<template>
  <div class="d-flex document-preview__wrapper">
    <div
      class="position-relative flex-grow-1 document-preview__document-wrapper"
    >
      <div class="document-preview__content">
        <v-skeleton-loader type="article@5" />
      </div>
    </div>
    <div class="document-preview__action-menu th-pl-16">
      <div class="">
        <v-skeleton-loader type="button" class="th-my-8" />
        <v-skeleton-loader type="button" class="th-my-8" />
        <v-skeleton-loader type="button" class="th-my-8" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentPreviewLoader",
};
</script>

<style lang="scss" scoped>
$skeleton-loader-button-width: 200px;
@import "../../scss/components/document-preview";
</style>

import { downloadFile } from "./files";

export const documentFileByType = (type, content, title = null) => {
  let mimeType = "";
  switch (type) {
    case "html":
      mimeType = "text/html";
      content = encodeURIComponent(content);
      break;
    case "pdf":
      mimeType = "application/pdf;base64";
      break;
    case "text":
    case "txt":
    case "md":
      mimeType = "text/plain;charset=UTF-8;base64";
      content = btoa(unescape(encodeURIComponent(content)));
      break;
  }

  downloadFile(content, title || `document.${type}`, mimeType);
};

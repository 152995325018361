<template>
  <div>
    <template v-if="documentContent">
      <div class="document-preview__wrapper row th-my-0">
        <div
          class="col-12 col-lg-9 order-1 order-lg-0 position-relative flex-grow-1 document-preview__document-wrapper"
        >
          <div class="document-preview__copy-html" v-if="copyContentEnabled">
            <v-btn small color="primary" outlined @click="copyLink">
              <v-icon size="14" class="th-mr-4"> mdi-content-copy </v-icon>
              {{ $translation.t("Copy to clipboard") }}
            </v-btn>
          </div>
          <div
            class="document-preview__content generated-document"
            v-html="documentContent"
          ></div>
          <div class="document-preview__demo" v-if="!getCurrentSubscription()">
            <h6 class="body2">
              {{ $translation.t("You see only little part of the document.") }}
            </h6>
            <h5 class="subtitle1" v-html="text" />
          </div>
        </div>
        <div
          class="col-12 col-lg-3 order-0 order-lg-1 th-mb-24 document-preview__action-menu"
          v-if="!noActionMenu"
        >
          <slot name="actions-menu" />
        </div>
      </div>
    </template>
    <document-preview-loader v-else />
  </div>
</template>

<script>
import { copy } from "@/utils/service/helpers";
import DocumentPreviewLoader from "./DocumentPreviewLoader";
import { mapGetters } from "vuex";

export default {
  name: "DocumentView",
  components: { DocumentPreviewLoader },
  props: {
    documentContent: {
      type: String,
      required: true,
    },
    // document: {
    //   type: Object,
    //   required: true,
    // },
    copyContentEnabled: Boolean,
    noActionMenu: Boolean,
  },
  computed: {
    // editLink() {
    //   return {
    //     name: "document_edit",
    //     params: {
    //       uuid: this.document.uuid,
    //     },
    //   };
    // },
    text() {
      return this.$translation.t(
        "To see full document please contact us on {email}",
        { email: '<a href="mailto:info@termshub.io">info@termshub.io</a>' }
      );
    },
    ...mapGetters(["getCurrentSubscription"]),
  },
  methods: {
    copyLink() {
      if (copy(this.documentContent)) {
        this.$toasted.info(this.$translation.t("Copied"), {
          icon: "mdi-content-copy",
        });
      }
      // try {
      //   dataLayer.push({
      //     event: `document_html_copy`,
      //     project_id: this.$route.params.project_id,
      //   });
      // } catch (e) {
      //   this.$log.notify(e);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/components/document-preview";
.document-preview__demo {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, white 30%);
  /*background: linear-gradient(180deg, rgba(red, 0.2) 0%, red 30%);*/
  padding: 64px 24px;
  text-align: center;
  margin: -140px 0 0;
  position: relative;
}
</style>
<style lang="scss">
@import "../../scss/components/generated-document";
</style>

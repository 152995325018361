<template>
  <div>
    <document-view :document-content="document.content">
      <document-actions-menu slot="actions-menu" :uuid="document.uuid" />
    </document-view>
    <document-view
      class="th-py-12"
      v-for="attachment in document.attachments"
      :key="attachment.slug"
      :document-content="attachment.content"
    >
      <div slot="actions-menu" />
    </document-view>
  </div>
</template>

<script>
import DocumentView from "../../../components/Document/DocumentPreview";
import DocumentActionsMenu from "../../../components/Document/DocumentActionsMenu";

export default {
  name: "Document",
  components: { DocumentActionsMenu, DocumentView },
  data() {
    return {
      document: {
        uuid: "",
        document: "",
        locale: "",
        version: "v1",
        content: "",
        attachments: [],
      },
    };
  },
  async beforeCreate() {
    this.document = await this.$axios.get("/api/v1/business/documents/render", {
      params: { format: "html", slug: this.$route.params.slug },
    });
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <th-card class="document-actions" body-classes="th-py-16" slot="actions-menu">
    <h6 class="subtitle2 th-mb-24" v-html="$translation.t('Action panel')"></h6>
    <v-btn
      class="th-mb-16"
      @click="renderAndDownload('pdf')"
      block
      outlined
      :loading="isActionInProgress('pdf')"
      :disabled="!!actionsInProgress.length"
      color="primary"
      id="project_document_action_menu_export_pdf"
    >
      <v-icon>mdi-pdf-box</v-icon>
      <span class="flex-grow-1">PDF</span>
    </v-btn>
  </th-card>
</template>

<script>
import { documentFileByType } from "../../utils/documents";
import ThCard from "../GeneralComponents/ThCard";

export default {
  name: "DocumentActionsMenu",
  components: { ThCard },
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: "",
      actionsInProgress: [],
    };
  },
  methods: {
    isActionInProgress(action) {
      return this.actionsInProgress.includes(action);
    },
    async renderAndDownload(type) {
      this.$event(
        "project_document_export",
        {
          document_type: this.$route.params.slug,
          export_type: type,
        },
        "document.export"
      );

      try {
        this.actionsInProgress.push(type);

        let content = await this.$axios.get(
          "/api/v1/business/documents/renderpdf",
          {
            params: { format: type, uuid: this.uuid },
          }
        );

        documentFileByType(
          type,
          content,
          this.$translation.tl(`document.${this.$route.params.slug}`, "lt") +
            `.${type}`
        );
      } catch (e) {
        if (e.showError !== false) {
          this.$toasted.error(
            "Error occurred while rendering document. Try again later",
            {
              icon: "mdi-close-octagon-outline",
            }
          );
        }
        this.$log.notify(e);
      }

      this.actionsInProgress = this.actionsInProgress.filter((a) => a !== type);
    },
  },
};
</script>

<style lang="scss" scoped></style>
